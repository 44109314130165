<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1280" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">关闭</a-button>
    </template>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryForms" layout="inline" class="query-form">
            <a-form-model-item label="设备名称" prop="devicename">
              <a-input v-model.trim="queryForms.devicename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="设备注册代码" prop="devicenum">
              <a-input v-model.trim="queryForms.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryForms" layout="inline" class="query-form">
            <a-form-model-item label="设备类型" prop="terminaltype">
              <a-select v-model="queryForms.terminaltype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryForms.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryForms.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯地址" prop="adress">
              <a-input v-model.trim="queryForms.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">设备列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="devtestlogid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="devicename" slot-scope="value, record">
            <span v-if="record.state_tf!=1" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="showDetail(value,record)">详情</a-button>
          </span>
          <span slot="rundata" slot-scope="value, record">
            <a-button type="link" size="small" @click="showRunDataDetail(value,record)">详情</a-button>
          </span>
          <span slot="terminaltype" slot-scope="text">
            <span class="text">{{terminaltypesMap[text]||''}}</span>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="runDataModalVisible" :width="1050">
      <template slot="footer">
        <a-button @click="runDataModalVisible=false">关闭</a-button>
      </template>
      <h3>终端异常事件列表</h3>
      <div style="width: 1000px">
        <a-table :columns="runColumns" :data-source="runData" row-key="abnoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-button v-if="value.abnocode!='1'" type="link" size="small" @click="showRunDataSonDetail(value,record)">详情</a-button>
            <a-button v-else-if="value.abnocode=='1'" type="link" size="small" @click="traceData(value,record)">追溯</a-button>
          </span>
          <span slot="abnotime" slot-scope="value, record">
            <span>{{moment(value,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")}}</span>
          </span>
          <span slot="abnocode" slot-scope="value, record">
            <span>{{abnoType[value]}}</span>
          </span>
        </a-table>
        <a-pagination v-model="currentPages" :total="totals" :page-size="paginations.pageSizes" show-size-changer @change="pageChanges" @showSizeChange="onShowSizeChanges" :show-total="(totals, range) => `共${totals}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </a-modal>
    <a-modal v-model="runDataSonVisible">
      <template slot="footer">
        <a-button @click="runDataSonVisible=false">关闭</a-button>
      </template>
      <h3 style="font-size: 22px">终端自检异常运行数据</h3>
      <a-form-model ref="run_modalForm" :model="runDatas" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="异常设备编码" prop="devicenum">
          <a-input v-model="runDatas.devicenum" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="异常类型" prop="abnocode">
          <a-input v-model="abnoType[runDatas.abnocode]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="异常上报时间" prop="abnotime">
          <a-input v-model="moment(runDatas.abnotime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='6'" label="设备重启时间" prop="_state_reboot_time">
          <a-input v-model="runDatas._state_reboot_time" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="实际运行高度和楼层高度的差值" prop="_hei_difference">
          <a-input v-model="runDatas._hei_difference" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="起始楼层" prop="_begin_floor">
          <a-input v-model="runDatas._begin_floor" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="终止楼层" prop="_end_floor">
          <a-input v-model="runDatas._end_floor" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="起始楼层的气压" prop="_begin_floor_pressure">
          <a-input v-model="Number(runDatas._begin_floor_pressure).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="终止楼层的气压" prop="_end_floor_pressure">
          <a-input v-model="Number(runDatas._end_floor_pressure).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="楼层高度队列常数的平均值" prop="_floor_hei_mean">
          <a-input v-model="Number(runDatas._floor_hei_mean).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行时间" prop="_run_time">
          <a-input v-model="Number(runDatas._run_time).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行平均速度" prop="_run_mean_speed">
          <a-input v-model="Number(runDatas._run_mean_speed).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='4'" label="录像失败开始时间" prop="_video_fail_begin_time">
          <a-input v-model="moment(runDatas._video_fail_begin_time,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='5'" label="录像失败结束时间" prop="_video_fail_end_time">
          <a-input v-model="moment(runDatas._video_fail_end_time,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行时间" prop="accumulative_run_time">
          <a-input v-model="Number(runDatas.accumulative_run_time/60).toFixed(2)+'小时'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行时间" prop="today_run_time">
          <a-input v-model="Number(runDatas.today_run_time).toFixed(2)+'分钟'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行次数" prop="accumulative_run_num">
          <a-input v-model="Number(runDatas.accumulative_run_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行次数" prop="today_run_num">
          <a-input v-model="Number(runDatas.today_run_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行里程" prop="cumulative_mileage">
          <a-input v-model="Number(runDatas.cumulative_mileage).toFixed(2)+'公里'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行里程" prop="today_mileage">
          <a-input v-model="Number(runDatas.today_mileage).toFixed(2)+'公里'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计开关门次数" prop="accumulative_door_num">
          <a-input v-model="Number(runDatas.accumulative_door_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日开关门次数" prop="today_door_num">
          <a-input v-model="Number(runDatas.today_door_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计人流量" prop="accumulative_passenger_num">
          <a-input v-model="Number(runDatas.accumulative_passenger_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日人流量" prop="today_passenger_num">
          <a-input v-model="Number(runDatas.today_passenger_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前状态" prop="car_status">
          <a-input v-model="carStatusMap[runDatas.car_status]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行方向" prop="car_direction">
          <a-input v-model="carDirectionMap[runDatas.car_direction]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="开锁区域" prop="door_zone">
          <a-input v-model="doorZoneMap[runDatas.door_zone]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前楼层" prop="car_position">
          <a-input v-model="runDatas.car_position" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="关门到位" prop="door_status">
          <a-input v-model="doorStatusMap[runDatas.door_status]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="服务模式" prop="service_mode">
          <a-input v-model="serviceModeMap[runDatas.service_mode]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前乘客数" prop="passenger_num">
          <a-input v-model="runDatas.passenger_num" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="sonModalVisible">
      <template slot="footer">
        <a-button @click="sonModalVisible=false">关闭</a-button>
      </template>
      <h3 style="font-size: 22px">终端自检日志</h3>
      <a-form-model ref="modalForm" :model="queryForm" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="自检设备编码" prop="devicenum">
          <a-input v-model="queryForm.state_dev_num" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="自检日志上报时间" prop="state_timestamp">
          <a-input v-model="moment(queryForm.state_timestamp,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="设备在线情况" prop="status">
          <a-input v-model="deviceoffline.status=='0'?'离线':'在线'" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="deviceoffline.status=='0'" label="离线开始时间" prop="offlinestart">
          <a-input v-model="moment(deviceoffline.offlinestart,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="deviceoffline.status=='0'" label="离线结束时间" prop="offlineend">
          <a-input v-model="moment(deviceoffline.offlineend,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="deviceoffline.status=='0'" label="离线时间" prop="offlinetime">
          <a-input v-model="deviceoffline.offlinetime" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_camera!='1'" label="自检镜头状态" prop="state_camera">
          <a-input v-model="queryForm.state_camera=='1'?'正常':'异常'" read-only v-if="queryForm.state_camera=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_board!='1'" label="自检主板状态" prop="state_board">
          <a-input v-model="queryForm.state_board=='1'?'正常':'异常'" read-only v-if="queryForm.state_board=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_ms5611!='1'" label="自检气压计状态" prop="state_ms5611">
          <a-input v-model="queryForm.state_ms5611=='1'?'正常':'异常'" read-only v-if="queryForm.state_ms5611=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_cpu!='1'" label="自检CPU24小时平均使用率" prop="state_cpu_average">
          <a-input v-model="Number(queryForm.state_cpu_average*100).toFixed(2)+'%'" read-only v-if="queryForm.state_cpu=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_cpu!='1'" label="自检CPU24小时最大使用率" prop="state_cpu_max">
          <a-input v-model="Number(queryForm.state_cpu_max*100).toFixed(2)" read-only v-if="queryForm.state_cpu=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_ram!='1'" label="RAM总容量" prop="state_ram_capacity">
          <a-input v-model="Number(queryForm.state_ram_capacity).toFixed(2)+'GB'" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_ram!='1'" label="自检RAM24小时平均使用率" prop="state_ram_average">
          <a-input v-model="Number(queryForm.state_ram_average*100).toFixed(2)+'%'" read-only v-if="queryForm.state_ram=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_ram!='1'" label="自检RAM24小时最大使用率" prop="state_ram_max">
          <a-input v-model="Number(queryForm.state_ram_max*100).toFixed(2)+'%'" read-only v-if="queryForm.state_ram=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_emmc!='1'" label="emmc总容量" prop="state_emmc_capacity">
          <a-input v-model="Number(queryForm.state_emmc_capacity).toFixed(2)+'GB'" read-only :style="{'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_emmc!='1'" label="自检emmc24小时最大使用率" prop="state_emmc_average">
          <a-input v-model="Number(queryForm.state_emmc_average*100).toFixed(2)+'%'" read-only v-if="queryForm.state_emmc=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_emmc!='1'" label="自检emmc24小时最大使用率" prop="state_emmc_max">
          <a-input v-model="Number(queryForm.state_emmc_max*100).toFixed(2)+'%'" read-only v-if="queryForm.state_emmc=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="TF卡状态" prop="state_tf">
          <a-input v-model="queryForm.state_tf==='1'?'正常':queryForm.state_tf==='2'?'疑似损坏':'损坏'" read-only v-if="queryForm.state_tf=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_tf!='1'" label="TF卡总容量" prop="state_tf_capacity">
          <a-input v-model="Number(queryForm.state_tf_capacity).toFixed(2)+'GB'" read-only v-if="queryForm.state_tf=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_tf!='1'" label="自检TF卡24小时平均使用率" prop="state_tf_average">
          <a-input v-model="Number(queryForm.state_tf_average).toFixed(2)+'%'" read-only v-if="queryForm.state_tf=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_tf!='1'" label="自检TF卡24小时最大使用率" prop="state_tf_max">
          <a-input v-model="Number(queryForm.state_tf_max).toFixed(2)+'%'" read-only v-if="queryForm.state_tf=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_brightness!='1'" label="自检图像亮度24小时平均值" prop="state_brightness_average">
          <a-input v-model="Number(queryForm.state_brightness_average).toFixed(2)" read-only v-if="queryForms.state_brightness=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_brightness!='1'" label="自检图像亮度24小时最大值" prop="state_brightness_max">
          <a-input v-model="Number(queryForm.state_brightness_max).toFixed(2)" read-only v-if="queryForms.state_brightness=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_cpu_temp!='1'" label="自检CPU24小时平均温度" prop="state_cpu_temp_average">
          <a-input v-model="Number(queryForm.state_cpu_temp_average).toFixed(2)+'℃'" read-only v-if="queryForm.state_cpu_temp=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_cpu_temp!='1'" label="自检CPU24小时最大温度" prop="state_cpu_temp_max">
          <a-input v-model="Number(queryForm.state_cpu_temp_max).toFixed(2)+'℃'" read-only v-if="queryForm.state_cpu_temp=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_gpu_temp!='1'" label="自检GPU24小时平均温度" prop="state_gpu_temp_average">
          <a-input v-model="Number(queryForm.state_gpu_temp_average).toFixed(2)+'℃'" read-only v-if="queryForm.state_gpu_temp=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_gpu_temp!='1'" label="自检GPU24小时最大温度" prop="state_gpu_temp_max">
          <a-input v-model="Number(queryForm.state_gpu_temp_max).toFixed(2)+'℃'" read-only v-if="queryForm.state_gpu_temp=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="queryForm.state_tf_database!='1'" label="自检TF上频繁操作数据库状态" prop="state_tf_database">
          <a-input v-model="queryForm.state_tf_database=='1'?'正常':'异常'" read-only v-if="queryForm.state_tf_database=='1'?color='white':color='red'" :style="{'background-color':color,'font-size':'20px'}"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <traceBack :visible.sync="traceVisible" ref="trace" :Abnotime="abnotime"></traceBack>
  </a-modal>
</template>
<script>
import {
  findDevAbnormalDevListByCondition,
  findDevAbnormalListByCondition, findDeviceOfflineListByCondition,
  findDevTestLogListByCondition,
  getTerminaltypeListByCondition
} from "A/ai";
import pagination from "@/mixins/pagination";
import moment from "moment";
import traceBack from "V/ai/monitoringEquipment/selfTest/devSelfTest/traceBack";
export default {
  name: "devAbnormalLogModal",
  mixins: [pagination],
  components: {
    traceBack
  },
  props:{
    visible: {
      default: false
    },
    showType:{
      default: '终端自检异常设备列表'
    },
    devDetail: {
      default: ''
    },
  },
  data() {
    return {
      moment,
      abnotime:'',
      traceVisible:false,
      currentPages: 1,
      paginations: {
        totals: 0,
        pageSizes: 10,
      },
      modalVisible: false,
      sonModalVisible:false,
      runDataModalVisible:false,
      runDataSonVisible:false,
      showAdvanced:false,
      terminaltypes: [],
      devicenum:'',
      queryForms:{
        devicename: '',
        devicenum: '',
        terminaltype: '',
        liftrescue: '',
        liftnum: '',
        adress: '',
        devicegroupid:'',
      },
      queryForm:{
        state_dev_num:'',
        state_timestamp:'',
        state_camera:'',
        state_board:'',
        state_ms5611:'',
        state_cpu_average:'',
        state_cpu_max:'',
        state_cpu:'',
        state_ram_average:'',
        state_ram_max:'',
        state_ram:'',
        state_emmc_average:'',
        state_emmc_max:'',
        state_emmc:'',
        state_tf_average:'',
        state_tf_max:'',
        state_tf:'',
        state_cpu_temp_average:'',
        state_cpu_temp_max:'',
        state_cpu_temp:'',
        state_gpu_temp_average:'',
        state_gpu_temp_max:'',
        state_gpu_temp:'',
        state_tf_database:'',
        state_brightness_average:'',
        state_brightness_max:'',
        state_brightness:'',
      },
      deviceoffline:{
        devicenum:'',
        offlinestart:'',
        offlineend:'',
        offlinetime:'',
        status:'',
      },
      runData:[],
      runColumns:[
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '电梯地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '异常事件类型',
          dataIndex: 'abnocode',
          key: 'abnocode',
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: 'abnocode' }
        },
        {
          title: '楼层高度差',
          dataIndex: '_hei_difference',
          key: '_hei_difference',
          width:80,
          ellipsis: true,
        },
        {
          title: '异常上报时间',
          dataIndex: 'abnotime',
          key: 'abnotime',
          ellipsis: true,
          width: 150,
          scopedSlots: { customRender: 'abnotime' }
        },
        {
          title: '终端自检数据',
          key: 'operation',
          align: 'center',
          width:95,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      abnoType:['','高度差异常','安全回路插片损坏','检修插片损坏','录像失败开始','录像失败结束'],
      totals:0,
      runDatas:{
        abnocode:'',
        devicenum:'',
        abnotime:'',
        _hei_difference:'',
        _begin_floor:'',
        _end_floor:'',
        _begin_floor_pressure:'',
        _end_floor_pressure:'',
        _floor_hei_mean:'',
        _run_time:'',
        _run_mean_speed:'',
        _video_fail_begin_time:'',
        _video_fail_end_time:'',
        accumulative_run_time:'',
        today_run_time:'',
        accumulative_run_num:'',
        today_run_num:'',
        cumulative_mileage:'',
        today_mileage:'',
        accumulative_door_num:'',
        today_door_num:'',
        accumulative_passenger_num:'',
        today_passenger_num:'',
        weather:'',
        temperature:'',
        car_status:'',
        car_direction:'',
        door_zone:'',
        car_position:'',
        door_status:'',
        service_mode:'',
        passenger_num:'',
        _state_reboot_time:'',
      },
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
          scopedSlots: { customRender: 'devicename' }
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
          width: 180,
        },
        {
          title: '设备型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '电梯注册码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
          width: 180,
        },
        {
          title: '电梯地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '异常运行数据',
          key: 'rundata',
          ellipsis: true,
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'rundata' }
        },
        {
          title: '自检异常数据',
          key: 'operation',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      carStatusMap: {
        '00': '停止',
        '01': '运行',
      },
      carDirectionMap: {
        '00': '无方向',
        '01': '上行',
        '02': '下行',
      },
      doorZoneMap: {
        '00': '轿厢在非开锁区域',
        '01': '轿厢在开锁区域',
      },
      doorStatusMap: {
        // '00': '未关门到位',
        '00': '开门',
        '01': '关门到位',
      },
      serviceModeMap: {
        '0': '停止服务',
        '1': '正常运行',
        '2': '检修',
        '3': '消防返回',
        '4': '消防员运行',
        '5': '应急电源运行',
        '6': '地震模式',
        '7': '未知',
      },
    }
  },
  computed: {
    modalTitle() {
      return this.showType
    },
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData();
        this.getTerminaltypes();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    pageChanges(page, size) {
      this.showRunDataDetail("",this.devicenum);
    },
    onShowSizeChanges(page, size) {
      this.paginations.pageSizes = size;
      this.showRunDataDetail("",this.devicenum);
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
      })
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryForms,
        status: '1',
        state_date: this.devDetail,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findDevTestLogListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          if (!(res.item[0] == undefined || res.item[0].length <= 0)) {
            this.tableData = res.item;
            this.totals = res.count;
            this.pagination.total = res.count;
          } else {
            this.totals = 0;
            this.tableData = [];
            this.pagination.total = 0
          }
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getDeviceOffline(devicenum){
      let params={
        devicenum:devicenum,
        status:"0"
      }
      findDeviceOfflineListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            this.deviceoffline=res.item[0]
          }
        }
      })
    },
    showDetail(value,record){
      let params={
        state_date:this.devDetail,
        state_dev_num:record.devicenum,
        status:'1'
      }
      findDevTestLogListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            this.queryForm=res.item[0]
            this.getDeviceOffline(record.devicenum);
            this.sonModalVisible=true
          }else{
            this.$message.info("未找到相应日期的自检日志")
          }
        }
      })
    },
    showRunDataDetail(value,record){
      let params={
        devicenum:record.devicenum?record.devicenum:this.devicenum,
        abnotime:this.devDetail,
        isabnodata:'1',
        pageno: this.currentPages,
        pagesize: this.paginations.pageSizes
      }
      if(record.devicenum){
        this.devicenum=record.devicenum
      }
      findDevAbnormalListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(!(res.item[0] == undefined || res.item[0].length <= 0)) {
            this.runData = res.item
            this.totals = res.count
            this.paginations.totals = res.count;
            this.runDataModalVisible = true
          }else{
            this.$message.info("未找到相应日期的自检异常运行数据")
          }
        }
      })
    },
    showRunDataSonDetail(value,record){
      let params={
        devicenum:record.devicenum,
        abnotime:moment(record.abnotime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"),
        isabnodata:'1',
        pageno: this.currentPages,
        pagesize: this.paginations.pageSizes
      }
      findDevAbnormalListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(!(res.item[0] == undefined || res.item[0].length <= 0)) {
            this.runDatas = res.item[0]
            this.paginations.totals = res.count
            this.runDataSonVisible = true
          }else{
            this.$message.info("未找到相应日期的自检异常运行数据")
          }
        }
      })
    },
    traceData(value,record){
      this.traceVisible=true
      this.abnotime=record.abnotime
    }
  }
}
</script>
<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>